import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Quart
			</title>
			<meta name={"description"} content={"Quart - переосмислення піци, один шматочок за раз"} />
			<meta property={"og:title"} content={"Quart"} />
			<meta property={"og:description"} content={"Quart - переосмислення піци, один шматочок за раз"} />
			<meta property={"og:image"} content={"https://quartzonfree.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quartzonfree.com/img/6978255.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quartzonfree.com/img/6978255.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quartzonfree.com/img/6978255.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://quartzonfree.com/img/location.png)"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Box sm-padding="64px 0 0 0" margin="32px 0 0 0" position="relative" padding="0 0 0 64px">
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
						Зв'яжіться з нами
						</Text>
					</Box>
				</Box>
				<Box padding="16px 46px 16px 46px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
							Місцезнаходження
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
							вул. Пирогова 151, м. Вінниця
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:manger@quartzonfree.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									manger@quartzonfree.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
							Номер телефону
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
							096 096 61 66
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});